<template>
  <section class="info-section" id="about">
    <div class="info-container">
      <div class="info-item">
        <h2>Filozofia nášho gymu</h2>
        <p>
          Náš gym nie je veľký, ale ponúka útulné prostredie, kde sa budete cítiť ako doma. Vytvárame rodinnú atmosféru, kde sa každý môže pohodlne zapojiť do tréningu bez ohľadu na úroveň skúseností. Okrem klasického cvičenia u nás nájdete komunitu podobne zmýšľajúcich ľudí, s ktorými môžete zdieľať svoje ciele a motivácie.
        </p>
      </div>
      <div class="info-item">
        <h2>Ako sa stať členom</h2>
        <p>
          Najprv sa zaregistruj online ako člen, aby sme mohli spracovať tvoje údaje a ušetrili ti čas pri prvej návšteve. Nebudeš sa musieť zdržiavať na recepcii – pri návšteve si môžeš na tablete pozrieť možnosti členstva, prípadne ho uzavrieš a začať cvičiť!
        </p>
      </div>
      <div class="info-item">
        <div class="main-box">
          <i class="fm fa-solid fa-wifi"></i>
          <i class="fm fa-solid fa-bolt"></i>
          <i class="fm fa-solid fa-snowflake"></i>
          <i class="fm fa-solid fa-weight-scale"></i>
          <i class="fm fa-solid fa-bottle-water"></i>
          <i class="fm fa-solid fa-spa"></i>
          <i class="fm fa-solid fa-music"></i>
          <i class="fm fa-solid fa-tv"></i>
        </div>
        <h2>Čo náš gym poskytuje</h2>
        <p>
          Aby sa každý člen cítil u nás príjemne, ponúkame pohodlné prostredie s klimatizáciou a bezplatnou Wi-Fi. Počas kardio tréningov si môžete užívať hudbu alebo sledovať televízor, čo spríjemní každý váš tréning. V ponuke máme aj trénerov, ktorí vám radi pomôžu, a praktické maličkosti ako nabíjacie stanice na mobil. Nechýba ani bioimpedančný stroj, ktorý vám napovie, ako je na tom vaše telo, svaly a tuk, a samozrejme váha, aby ste mali všetko potrebné po ruke. Dbáme aj na pitný režim, a preto máte u nás zadarmo k dispozícii iontové a energetické nápoje. Okrem cvičenia si môžete dopriať aj masáže a iné relaxačné služby, ktoré vám pomôžu zregenerovať telo a myseľ po náročnom tréningu.
        </p>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'BasicInfoSection',
};
</script>

<style scoped>

.main-box {

}

.fm {
  font-size: 24px;
  padding: 20px 40px;
}
.info-section {
  background-color: #f7f7f7; /* Light gray background */
  padding: 60px 20px;
  text-align: center;
}

.info-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.info-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-item h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.info-item p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.info-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .info-container {
    flex-direction: column;
    align-items: center;
  }

  .info-item {
    width: 100%;
    max-width: 290px;
    min-width: 290px;
    margin-bottom: 20px;
    padding: 5px 25px;
  }
}
</style>
