<template>
  <footer class="footer-section">
    <div class="footer-container">
      <div class="footer-logo">
        <img src="@/assets/logo.png" alt="MF Fitness Logo" />
      </div>
      <div class="footer-links">
        <ul>
          <li><a href="#home">Domov</a></li>
          <li><a href="#services">Služby</a></li>
          <li><a href="#about">O nás</a></li>
          <li><a href="#contact">Kontakt</a></li>
        </ul>
      </div>
      <div class="footer-social">
        <a href="https://facebook.com/mffitness.SK/"><img src="@/assets/facebook-icon.png" alt="Facebook" /></a>
        <a href="https://www.instagram.com/mff_fitko"><img src="@/assets/instagram-icon.png" alt="Instagram" /></a>
      </div>
    </div>
    <div class="footer-doc">
      <a href="https://mffitness.sk/podmienky.pdf">Všeobecné obchodné podmienky</a>
      <a href="https://mffitness.sk/gdpr.pdf">Vyhlásenie o ochrane osobných údajov</a>
    </div>
    <div class="footer-bottom">
      <p>&copy; 2024 MF Fitness. All Rights Reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
};
</script>

<style scoped>

.footer-doc a {
  color: white;
  margin: 10px;
}
.footer-section {
  background-color: #000; /* Black background */
  padding: 40px 20px;
  color: #fff;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-logo img {
  align-items: center;
  max-width: 150px;
}

.footer-links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.footer-links ul li {
  font-size: 16px;
}

.footer-links ul li a {
  text-decoration: none;
  color: #fff200; /* Bright yellow color */
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #fff;
}

.footer-social a {
  margin: 0 10px;
}

.footer-social img {
  width: 24px;
  height: 24px;
  transition: filter 0.3s ease;
}

.footer-bottom {
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 14px;
  color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-links ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer-social {
    margin-top: 20px;
  }
  .footer-links {
    width: 100%;
    position: relative;
    text-align: center;
    display: block;
  }
}
</style>
