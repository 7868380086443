<template>
  <section class="contact-section" id="contact">
    <div class="contact-container">
      <div class="contact-item">
        <h2>Kontaktuj nás na:</h2>
        <p>
          <strong>Tel. číslo:</strong> +421 947 955 575<br />
          <strong>Email:</strong> mff.fitko@gmail.com
        </p>
      </div>
      <div class="contact-item">
        <h2>Kde nás najdeš?</h2>
        <p>
          Zahradnícka 95<br />
          Bratislava, 821 08
        </p>
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=MF+Fitness&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          width="100%"
          height="200"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div class="contact-item">
        <h2>Kedy máme otvorené?</h2>
        <p>
          <strong>Pondelok - Piatok:</strong> 6:00 - 11:00, 14:00 - 23:00<br />
          <strong>Sobota - Nedela:</strong> 9:00 - 21:00
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactSection',
};
</script>

<style scoped>
.contact-section {
  background-color: #1e1e1e; /* Dark background to contrast with previous sections */
  padding: 60px 20px;
  color: #fff;
  text-align: center;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.contact-item {
  flex: 1;
  min-width: 280px;
  margin-bottom: 20px;
}

.contact-item h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #fff200; /* Bright yellow color */
}

.contact-item p {
  font-size: 16px;
  line-height: 1.6;
}

iframe {
  border-radius: 8px;
  margin-top: 15px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-item {
    width: 100%;
    max-width: 360px;
    margin-bottom: 0px;
  }

  iframe {
    height: 300px; /* Increased height for better visibility on mobile */
  }
}
</style>
