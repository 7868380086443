<template>
  <Menu/>
  <Hero/>
  <Info/>
  <Contact/>
  <Footer/>
</template>

<script>
import Menu from './components/Menu.vue'
import Hero from './components/Hero.vue'
import Info from './components/Info.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Hero,
    Info,
    Contact,
    Footer
  }
}
</script>

<style>

body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
