<template>
  <header class="header" id="home">
    <div class="container">

      <nav class="nav-left"  :class="{ 'nav-open': isMenuOpen }">
        <ul>
          <li><a href="#home" @click="closeMenu">Domov</a></li>
          <li><a href="#services" @click="closeMenu">Služby</a></li>
        </ul>
      </nav>
      <div class="logo">
        <img src="@/assets/logo.png" alt="MF Fitness Logo" />
      </div>
      <nav class="nav-right"  :class="{ 'nav-open': isMenuOpen }">
        <ul>
          <li><a href="#about" @click="closeMenu">O nás</a></li>
          <li><a href="#contact" @click="closeMenu">Kontakt</a></li>
        </ul>
      </nav>
      <nav class="nav" :class="{ 'nav-open': isMenuOpen }">
        <ul>
          <li><a href="#home" @click="closeMenu">Domov</a></li>
          <li><a href="#services" @click="closeMenu">Služby</a></li>
          <li><a href="#about" @click="closeMenu">O nás</a></li>
          <li><a href="#contact" @click="closeMenu">Kontakt</a></li>
        </ul>
      </nav>
      <div class="menu-icon" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #000; /* Dark gray background */
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo img {
  max-width: 100px;
}

.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  z-index:999;
}

.menu-icon span {
  display: block;
  height: 3px;
  background-color: #fff200; /* Bright yellow */
  border-radius: 2px;
  transition: all 0.3s ease;
}

.nav-left,
.nav-right {
  display: flex;


}

.nav-left ul,
.nav-right ul {
  list-style: none;
  display: flex;
  gap: 60px;
  margin: 0;
  padding: 0;
}

.nav-left ul li,
.nav-right ul li {
  font-size: 16px;
}

.nav-left ul li a,
.nav-right ul li a {
  text-decoration: none;
  color: #fff200; /* Bright yellow color */
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-left ul li a:hover,
.nav-right ul li a:hover {
  color: #fff; /* White color on hover */
}

.nav {
  display: none;
  gap: 20px;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav ul li {
  font-size: 16px;
}

.nav ul li a {
  text-decoration: none;
  color: #fff200; /* Bright yellow color */
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: #fff; /* White color on hover */
}

/* Mobile styles */
@media (max-width: 768px) {
  .menu-icon {
    display: flex;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .nav {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 150px;
    background-color: #1e1e1e;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 100;
    text-align: right;
  }

  .nav-open {
    transform: translateX(0);
  }

  .nav ul {
    flex-direction: column;
    gap: 20px;
  }
    .nav-left,
    .nav-right {
      display: none;
    }


}
</style>
