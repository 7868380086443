<template>
  <section class="hero-section" id="services">
    <div class="overlay">
      <div class="content">
        <h1 class="title">Tu máš priestor<br />pre svoj rozvoj!</h1>
        <h1 class="title2">Čakáme ťa</h1>
        <div class="membership-options">
          <div class="option">
            <h2>Jednorázový vstup</h2>
            <ul>
              <li>Bez akéhokoľvek vypĺňania, jednoducho prídeš a cvičíš. Vyskúšaj si naše moderné vybavenie a motivujúcu atmosféru s jednorazovým vstupom. Príď, zaži to na vlastnej koži a rozhodni sa, či sa chceš stať súčasťou našej komunity!</li>
              <li class="price" style="bottom: 25px;">4,90€</li>
            </ul>
          </div>
          <div class="option">
            <h2>Mesačné členstvo</h2>
            <ul>
              <li>Využi neobmedzený prístup k modernému vybaveniu a rozmanitým cvičebným možnostiam. Bez viazanosti a s flexibilitou – pridaj sa na mesiac a užívaj si svoje tréningy na maximum!</li>
              <li class="price">29,90€<div class="m">mesačne</div></li>
            </ul>
          </div>
        </div>
          <div class="membership-options">
          <div class="option">
            <h2>Polročné členstvo</h2>
            <ul>
              <li>Chceš cvičiť pravidelne a zároveň ušetriť? Využi polročné členstvo, ktoré je lacnejšie než mesačné, a získaš prístup k modernému vybaveniu na pol roka za výhodnú cenu. Nechaj sa motivovať lepšou cenou a začni hneď teraz!</li>
              <li class="price">24,90€<div class="m">mesačne</div></li>
            </ul>
          </div>
          <div class="option">
            <h2>Ročné členstvo</h2>
            <ul>
              <li>Využi našu najvýhodnejšiu ponuku – ročné členstvo ti prinesie neobmedzený prístup k modernému vybaveniu počas celého roka za bezkonkurenčnú cenu. Trénuj bez prestávok, užívaj si maximálnu flexibilitu a ušetri ešte viac oproti kratším členstvám!</li>
              <li class="price">19,90€ <div class="m">mesačne</div></li>
            </ul>
          </div>
        </div>
        <a href="/newclient/"><button class="join-button">Pridaj sa k nám online, bez záväzkov</button></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MembershipSection',
};
</script>

<style scoped>
.hero-section {
  position: relative;
  height: 1035px;
  background: url('@/assets/bg.jpeg') no-repeat center center/cover;
}
.m {
  font-size: 14px;
  margin: 0;
  margin-left: 65px;
}

.overlay {
  position: absolute;
  height: 1035px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dark overlay for better text visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content {
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px; /* Reduced top gap */
}

.title {
  margin-top: -30px;
  font-size: 48px;
  margin-bottom: 0px; /* Reduced bottom margin */
  color: #fff200;
  text-transform: uppercase;
  line-height: 60px;
}

.title2 {
  font-size: 32px;
  margin-bottom: 60px; /* Reduced bottom margin */
  text-transform: uppercase;
  font-weight: 600;
}
.price {
  font-size: 42px !important;
  font-weight: 800;
  float: right;

  position: absolute;
  bottom: 0px;
  right: 25px;
}

.membership-options {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.option {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 480px;
  padding: 40px;
  padding-bottom: 60px;
  text-align: left;
}

.option h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff200; /* Bright yellow color */
}

.option ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option ul li {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.join-button {
  background-color: #fff200; /* Bright yellow */
  color: #1e1e1e;
  border: none;
  padding: 15px 0;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  max-width: 640px; /* This matches the combined width of the two options plus the gap */
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.join-button:hover {
  background-color: #e6bf1a;
}

.option:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero-section {
    margin-top: 80px;
    height: 2062px;
  }

  .overlay {
    height: 2062px;
  }

  .title {
    font-size: 36px;
  }

  .membership-options {
    flex-direction: column;
    align-items: center;
  }

  .option {
    width: 100%;
    max-width: 300px;
  }

  .join-button {
    width: 360px; /* Full width on mobile */
  }
  .price {
    font-size: 42px !important;
    font-weight: 800;
    float: right;

    position: relative;
    bottom: 0px;
    right: 25px;
    padding-top: 40px;
  }
  .m {
    font-size: 14px;
    margin: 0;
    margin-left: 65px;
  }
  .option {
    padding-bottom: 0px;
    padding-right: 0px;
  }
}
</style>
